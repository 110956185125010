<script setup lang="ts">
import { nextTick, onMounted, ref } from 'vue';

declare const $: typeof import('jquery');

const props = defineProps<{
  title: string;
  msg: string;
  type: string;
  duration: number;
  dismissable: boolean;
}>();

const showing = ref(true);

onMounted(() => {
  nextTick(() => {
    $('#alert').hide().slideDown();
  });

  if (!props.dismissable && props.duration) {
    setTimeout(() => {
      $('#alert').slideUp();
      setTimeout(() => {
        showing.value = false;
      }, 401);
    }, props.duration);
  }
});
</script>

<template>
  <teleport to="body" v-if="showing">
    <div id="alert">
      <div id="alert-title" :class="'alert-' + type">{{ title }}</div>
      <div id="alert-content">
        <div v-html="msg"></div>
        <div class="alert-minclose" @click="showing = false" v-if="dismissable">&times;</div>
      </div>
    </div>
  </teleport>
</template>

<style scoped>
#alert {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 45vw;
  z-index: 5002;
  border-width: 3px;
  box-shadow: 2px 2px 20px black;
  border-radius: 5px;
  transition: background 0.5s;
  max-height: 90vh;
  overflow: auto;
  background: #fff;
}

#alertsm-title {
  padding: 10px 20px;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
}

#alertsm-content {
  font-size: 11pt;
  padding: 5px;
  overflow: hidden;
  text-align: left;
}

.alertsm-contentrow {
  padding: 5px;
  margin: 8px;
  border-radius: 3px;
  border-bottom: #333 1px dotted;
  color: #333;
}

#alert-title {
  padding: 10px 20px;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
}
#alert-content {
  padding: 10px 20px;
  margin: 5px 0px 10px 0px;
  max-height: 80vh;
  overflow: auto;
}
.point {
  cursor: pointer;
}
.alert-success {
  background: #517c67;
  color: #fff;
}
.alert-warning {
  background: #f2cc5c;
  color: #333;
}
.alert-info {
  background: #337ab7;
  color: #fff;
}
.alert-input div {
  margin: 5px;
}
.alert-input,
.alert-normal {
  background: #fff;
  color: #333;
}
.alert-danger {
  background: #a03131;
  color: #fff;
}
.alert-minclose {
  font-size: 18px;
  color: #999;
  position: absolute;
  right: 7px;
  top: 7px;
  z-index: 1002;
  border-radius: 50%;
  background: #fff;
  border: #999 1px solid;
  padding: auto;
  height: 25px;
  width: 25px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 25px;
}
.alert-minclose:hover {
  background: #999;
  color: #fff;
}
.main-content {
  background: #333;
  width: 100vw;
  height: 100vh;
}
</style>
