import { initializeApp } from 'firebase/app';
import { collection, getFirestore, type CollectionReference } from '@firebase/firestore';
import { getAuth } from '@firebase/auth';
import type {
  UserDocument,
  UserPermissionDocumentRow,
  UserManagementPermissionsRequestDoc,
} from '../../functions/src/facility-safety-environmental-types';

const app = initializeApp(
  {
    apiKey: 'AIzaSyDRyi-y11n_xeimcrrvDjR6AVIOB5hpkrQ',
    authDomain: 'facility-audits.firebaseapp.com',
    projectId: 'facility-audits',
    storageBucket: 'facility-audits.appspot.com',
    messagingSenderId: '344755032649',
    appId: '1:344755032649:web:b43bb4ba330bc8a395a598',
    measurementId: 'G-MYJ36C878D',
  },
  'facility-audits'
);

const db = getFirestore(app);
export const usersCol = collection(db, 'users') as CollectionReference<UserDocument, UserDocument>;
export const permissionsCol = collection(db, 'permissions') as CollectionReference<
  UserPermissionDocumentRow,
  UserPermissionDocumentRow
>;
export const permissionsRequestsCol = collection(db, 'permissionsRequests') as CollectionReference<
  UserManagementPermissionsRequestDoc,
  UserManagementPermissionsRequestDoc
>;
export const auth = getAuth(app);
